<template>

    <div class="app">

        <div id="step1" class="wizard1" v-if="step==1">
            
            <div>

                <div class="componente1"></div>
                <div class="sub_componente1"></div> 

                <div class="container-fluid top3">
                    <div class="row text-center" >
                        <div class="col-md-8 offset-md-2 color_back3" >
                                <p class="  color_letra mb-4"> <b>Selecciona qué quieres que haga tu chatbot</b></p>
                            <div class="row margen-row">
                                <div class="col-md-6 ">
                                    <img class="apps3 margen "  @click="goToStep(2)" src="../assets/componente_4.png" alt=""><p class="color_letra_2"> Ventas de tu <br> e-commerce</p>
                                </div> 
                                <div class="col-md-6 ">
                                    <img class="apps3 margen "  @click="goToStep(2)" src="../assets/componente_2.png" alt=""><p class="color_letra_2">Atención a <br> clientes</p>
                                </div>
                            </div> 
                                
                        </div>
                    </div>
                </div>
            </div> 
        </div>
            
       <div id="step2" class="wizard1" v-if="step==2">
            
            <div>

                <div class="componente1"></div>
                <div class="sub_componente1"></div> 

                <div class="container-fluid top3">
                    <div class="row text-center" >
                        <div class="col-md-8 offset-md-2 color_back3" >
                                <p class="  color_letra mb-4" > <b>Tienes un e-commerce al cual podamos enlazar tu <br> chatbot</b></p>
                            <div class="row margen-row">
                                <div class="col-md-6 ">
                                    <img class="apps3 margen " @click="goToStep(3)" src="../assets/componente_4.png" alt=""><p class="color_letra_2"> Si</p>
                                </div> 
                                <div class="col-md-6 ">
                                    <img class="apps3 margen "  @click="goToStep(3)" src="../assets/componente_2.png" alt=""><p class="color_letra_2">No</p>
                                </div>
                            </div> 
                                
                        </div>
                    </div>
                </div>
            </div> 
        </div>
        <div id="step3" class="wizard1" v-if="step==3">
            <div>

                <div class="componente1"></div>
                <div class="sub_componente1"></div> 

                <div class="container-fluid top3">
                    <div class="row text-center" >
                        <div class="col-md-8 offset-md-2 color_back3" >
                                <p class="  color_letra mb-4" > <b>Tienes una base de datos de tu inventario con: <br> imagenes, precio y descripción de tus productos?</b></p>
                            <div class="row margen-row">
                                <div class="col-md-6 ">
                                    <img class="apps3 margen "  @click="goToStep(4)" src="../assets/componente_4.png" alt=""> <p class="color_letra_2">Si</p>
                                </div> 
                                <div class="col-md-6 ">
                                    <img class="apps3 margen "  @click="goToStep(4)" src="../assets/componente_2.png" alt=""> <p class="color_letra_2">No</p>
                                </div>
                            </div> 

                        </div>
                    </div>
                </div>
                
            </div> 
        </div>

         <div id="step4" class="wizard1" v-if="step==4">
            <div>

                <div class="componente1"></div>
                <div class="sub_componente1"></div> 

                <div class="container-fluid top">
                    <div class="row text-center" >
                        <div class="col-md-8 offset-md-2 color_back4" >
                                <p class=" color_letra mb-4" > <b>¿En qué plataforma tienes tu e-commerce?</b></p>
                            <div class="row ">
                                <div class="col-md-3 "> 
                                    <img class="apps2 margen "  @click="goToStep(5)" src="../assets/componente_4.png" alt=""> <br><p class="color_letra_2">Shopify</p>
                                </div> 
                                <div class="col-md-3 ">
                                    <img class="apps2 margen "  @click="goToStep(5)" src="../assets/componente_2.png" alt=""> <br><p class="color_letra_2">Woo commerce</p>
                                </div> 
                                <div class="col-md-3 ">
                                    <img class="apps2 margen "  @click="goToStep(5)" src="../assets/componente_4.png" alt=""> <br><p class="color_letra_2">Prestashop</p>
                                </div>
                                <div class="col-md-3 "> 
                                    <img class="apps2 margen "  @click="goToStep(5)" src="../assets/componente_4.png" alt=""> <br><p class="color_letra_2">Ecwild</p>
                                </div> 
                                    <div class="col-md-3 ">
                                     <img class="apps2 margen "  @click="goToStep(5)" src="../assets/componente_2.png" alt=""> <br><p class="color_letra_2">Wix</p>
                                 </div>
                                 <div class="col-md-3 ">
                                     <img class="apps2 margen "  @click="goToStep(5)" src="../assets/componente_4.png" alt=""> <br><p class="color_letra_2">Otro </p>
                                </div>
                            </div> 
                            
                        </div>
                    </div>
                </div>
                
            </div> 
        </div>

        <div id="step5" class="wizard1" v-if="step==5">
            <div>

                <div class="componente1"></div>
                <div class="sub_componente1"></div> 

                <div class="container-fluid top">
                    <div class="row text-center" >
                        <div class="col-md-8 offset-md-2 color_back" >
                                <p class="  color_letra mb-4" > <b>¿Que tan "humana" quieres que sea <br> la conversación con tu chatbot?</b></p>
                            <div class="row ">
                                <div class="col-md-4 "> 
                                    <img class="apps2 margen "  @click="goToStep(6)" src="../assets/componente_4.png" alt=""> <br><p class="color_letra_2">Muy humana</p>
                                </div> 
                                <div class="col-md-4 ">
                                    <img class="apps2 margen "  @click="goToStep(6)" src="../assets/componente_2.png" alt=""> <br><p class="color_letra_2">intermedio</p>
                                </div> 
                                <div class="col-md-4 ">
                                    <img class="apps2 margen " @click="goToStep(6)" src="../assets/componente_4.png" alt=""> <br><p class="color_letra_2">conversación simple</p>
                                </div>
                            </div> 
                           
                        </div>
                    </div>
                </div>
                
            </div> 
        </div>


        <div id="step6" class="wizard1" v-if="step==6">
            
            <div>

                <div class="componente1"></div>
                <div class="sub_componente1"></div> 

                <div class="container-fluid top">
                    <div class="row " >
                        <div class="col-md-8 offset-md-2 color_back5" >
                            <p class="  color_letra mb-4" > <b>Dejanos tus datos</b></p>
                            <div class="input mb-3">
                        <input type="text" class="form-control tamaño_input sombra p-4 bg-light rounded" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1">
                    </div>
                    <div class="input mb-3">
                        <input type="text" class="form-control tamaño_input sombra p-4 bg-light rounded" placeholder="whatsapp" aria-label="whatsapp" aria-describedby="basic-addon1" >
                    </div>
                    <div class="input mb-3">
                        <input type="text" class="form-control tamaño_input sombra p-4 bg-light rounded" placeholder="email" aria-label="email" aria-describedby="basic-addon1">
                    </div>

                            <button class="btn color-b2" @click="goToStep(7)">Guardar</button>
                            
                        </div>
                    </div>
                </div>
            </div> 
        </div>
        <div id="step7" class="wizard1" v-if="step==7">
            
            <div>

                <div class="componente1"></div>
                <div class="sub_componente1"></div> 

                <div class="container-fluid top">
                    <div class="row text-center" >
                        <div class="col-md-8 offset-md-2 color_back5" >
                            <h1 class="color_letra_parrafo"> <b>¡Listo!</b></h1>
                            <p class="color_letra_parrafo mt-3"> <b>$34,000 mnx</b></p>
                            <p class="color_letra2"> Este es el costo aproximado para el desarrollo. Nos gusta platicar más acerca de tus necesidades y ver como podemos ayudarte de la mejor forma</p>

                           
                            <button class="btn color-b" @click="goToStep(7)">Hablar con un asesor</button>
                            
                        </div>
                    </div>
                </div>
            </div> 
        </div>

</div>

    
      

</template>

<script>

export default {
 name: 'wizard_w',
  components: {
    
  },
  data(){
      return{
          step: 1

      }
  },
  async mounted(){

  },
  methods:{
      goToStep(item){
          this.step=item

      },
      
  }
}
</script>
<style scoped>
.wizard1{
  display:flex ;
  height: 750px;
  position: relative;
}

.componente1{
  background-image: url("../assets/componente_1.png");
  width:275px;
  height:250px;
  background-size: contain;
  background-repeat: no-repeat;
  top: 10%;

  
} 
.sub_componente1{
  background-image: url("../assets/componente_1.png");
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  width:275px;
  height:250px;
  right:0px ;
  top: 539px;
  transform: rotate(180deg);
}

.apps{
  max-height:70% ;
  max-width: 80%;
}
.top{
  position: absolute;
  top: 20%;
}
.color_back{
  background-color: #1673ffde;
  border-radius: 25px;
  padding-top: 30px;
  padding-bottom: 20px;
  height: 450px;
  margin-top: 5%;      
  }
  .color_back5{
  background-color: #1673ffde;
  border-radius: 25px;
  padding-top: 30px;
  padding-bottom: 20px;
  height: 450px;
  margin-top: 5%;      
  }
.color_letra{
  color: #ffffff;
  text-align: center;
  font-size:35px;
}
.color_letra_2{
  color: #ffffff;
  font-size: 25px;
}
.margen{
    margin-bottom: 3%;
}
.apps2{
    max-height:80% ;
    max-width: 60%;
}
.apps3{
    max-height:90% ;
    max-width: 80%;
   
}

.color_back3{
  background-color: #257cffde;
  border-radius: 25px;
  padding-top: 30px;
  padding-bottom: 20px;
  height: 450px;
  margin-top: 5%;      
  }
  .color_back4{
  background-color: #257cffde;
  border-radius: 25px;
  padding-top: 30px;
  padding-bottom: 20px;
  height: 500px;
  margin-top: 5%;      
  }
.top3{
  position: absolute;
  top: 20%;
  }
  .margen-row{
    margin-left: 20%;
    margin-right: 20%;
  }
  .margen-row-2{
    margin-left: 20%;
    margin-right: 20%;
  }
  .color-b{
      background-color: blueviolet;
      color: rgb(242, 212, 251);
      border-radius: 18px;
      width: 500px;
      height: 70px;
      font-size: 25px;
  }.color-b2{
      background-color: rgb(174, 36, 202);
      color: rgb(242, 212, 251);
      border-radius: 18px;
      width: 400px;
      height: 70px;
      font-size: 25px;
  }
  .color_letra_parrafo{
  color: hsl(0, 0%, 100%);
  text-align: center;
  font-size:45px;
}
.color_letra2{
  color: #ffffff;
  font-size: 28px;
  text-align: justify;
  margin-left: 70px;
  margin-right: 70px;
}
.tamaño_input{
    width:70%;
    margin-left: 15%;
    margin-right: 15%;
    height: 5%;
}
.sombra{
      box-shadow: 0 1px 1px 0 rgba(109, 115, 124, 0.198), 0 0px 7px 0 rgba(45, 112, 255, 0.19);
}

@media only screen and (max-width: 780px) {
    .apps{
        max-height:55% ;
        max-width: 65%;
        margin-top: 0%;
    }
    .apps2{
    max-height:55% ;
    max-width: 65%;
}
    .apps3{
    max-height:55% ;
    max-width: 65%;
   
}
    
    .color_back{
    background-color: #1673ffde;
    border-radius: 25px;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-right: 5%;
    margin-left: 5% ;
    height: 100%;
    }
    .color_back3{
    background-color: #1673ffde;
    border-radius: 25px;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-right: 5%;
    margin-left: 5% ;
    height: 100%;
    }
    .color_back4{
    background-color: #1673ffde;
    border-radius: 25px;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-right: 5%;
    margin-left: 5% ;
    height: 100%;
    }
    .color_letra {
      text-align: center;
      font-size: 30px;
    }
    .sub_componente1{
        display: none;
    }
    .color-b, .color-b2{
        width: 300px;
    }
    .color_letra_parrafo{
        font-size:35px;
    }
    .color_back5{
     background-color: #1673ffde;
    border-radius: 25px;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-right: 5%;
    margin-left: 5% ;
    height: 50%;
    }   
    .color_letra2{
    color: #ffffff;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
}
.tamaño_input{
    width: 280px;
    margin-left: 5%;
    margin-right: 5%;
    height: 5%;
}
    

}


</style>